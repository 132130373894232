@import '../../../../__variables.scss';

.holiday-request {
  padding: 10px 0px;
  margin: 10px 0px;
  border-top: thin solid $gray;
  .cta-wrapper {
    display: flex;
    gap: 10px;
    margin-top: 5px;
    .cta {
      flex: 1 1 0px;
    }
  }
}
