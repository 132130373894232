@import '../../../__variables.scss';

#dashboard-layout {
  display: flex;
  #sidebar {
    width: 20vw;
    height: calc(100vh - 80px);
    background-color: $white;
    padding: 40px 30px;
    position: relative;
    .logo {
      img {
        max-width: 100%;
        max-height: 40px;
      }
    }
    .navigation {
      margin-top: 20%;
      .title {
        font-size: $text-large;
        font-family: 'Stolzl-Bold';
        border-bottom: thin solid $gray;
        margin-bottom: 10px;
      }
      .item a {
        color: $black;
        padding: 5px;
        display: block;
      }
    }
    .logout-wrapper {
      position: absolute;
      bottom: 0px;
      left: 0px;
      display: flex;
      width: 100%;
      padding: 40px 30px;
      align-items: center;
      gap: 10px;
      .logout {
        cursor: pointer;
        color: $red;
        font-size: medium;
      }
    }
  }
  #dashboard-content-wrapper {
    width: 80vw;
    min-height: 100%;
    background-color: $bg;
    position: relative;
    padding: 40px;
    #dashboard-content {
      min-height: 100%;
    }
    &.card {
      max-height: 100vh;
      overflow: hidden;
      #dashboard-content {
        max-height: 100%;
        overflow: scroll;
        min-height: auto;
        border-radius: $default-border-radius;
        box-shadow: $default-box-shadow;
        background-color: $white;
      }
    }
  }
}
