@import '../../../__variables.scss';

#footer {
  background-color: $white;
  border-top: 2px solid $blue;
  padding: 20px 0px;
  .container {
    display: flex;
    .logo,
    .copyright {
      width: 50%;
    }
    .logo img {
      height: 25px;
    }
    .copyright {
      display: flex;
      align-items: center;
      div {
        font-size: $text-small;
        display: inline-block;
        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }
}

@media (max-width: $breakpoint) {
  #footer {
    .container {
      display: block;
      .logo,
      .copyright {
        width: 100%;
        text-align: center !important;
      }
      .copyright {
        display: block;
      }
    }
  }
}
