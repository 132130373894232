@import '../../../../__variables.scss';

.employee-list-item {
  display: flex;
  cursor: pointer;
  padding: 5px;
  border-bottom: thin solid $gray;
  .prop {
    flex: 1 1 0px;
    &:last-child {
      text-align: right;
    }
  }
  &:hover {
    background-color: $blue;
    .prop {
      color: $white;
    }
  }
  &:last-child {
    border-bottom: none;
  }
}
