@import '../../../__variables.scss';

#holiday {
  .input {
    margin-bottom: 5px;
  }
  .message {
    margin: 10px 0px;
  }
}
