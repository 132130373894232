@import '../../../__variables.scss';

#profile {
  .image-wrapper {
    width: 200px;
    margin-bottom: 10px;
  }
  .input {
    margin-bottom: 5px;
  }
  .message {
    margin: 10px 0px;
  }
}
