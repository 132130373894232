@import '../../../_variables';

.custom-MUI-input {
  &.MuiTextField-root {
    .MuiFilledInput-root:after,
    .MuiInput-root:after {
      border-color: $blue;
    }
    .MuiInputLabel-shrink {
      color: $blue;
    }
    .Mui-disabled {
      opacity: 1;
      -webkit-text-fill-color: $black;
    }
  }

  @media (max-width: $breakpoint) {
  }
}
