@import '../../../__variables.scss';

#header {
  &.user-scrolled {
    box-shadow: 5px 5px 23px -13px $black;
  }

  background-color: $bg;
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 1000;
  padding: 15px;

  .logo {
    cursor: pointer;
    font-weight: bolder;
    color: $white;
    img {
      max-height: 40px;
    }
  }

  .logo-wrapper {
    height: 100%;
  }

  @media (max-width: $breakpoint) {
    .logo-wrapper {
      text-align: center;
    }
  }
}
